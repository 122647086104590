.LoginPage{
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
    .Backdrop{
        width: 100%;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 1;
        overflow-x: clip;
    }
    .BackgroundImage{
        width: 100%;
        height: 100vh;
        position: absolute;
        z-index: 0;
        overflow-x: clip;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .LoginContainer{
        display: flex;
        width: 100%;
        height: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }



}