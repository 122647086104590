.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f5f5f5;
    box-shadow: 0 0 10px #ccc;
    margin: 20px;
    .TableHeader{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        width: 100%;
        min-height: 30px;
        height: fit-content;
        background-color: #ccc;
        h3{
            width: 300px;
            word-wrap: break-word;
            font-weight: bold;
        }
    }
    .Item{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        text-align: center;
        width: 100%;
        min-height: 30px;
        height: fit-content;
        border-color: darkgrey;
        border-style: solid;
        border-width: 1px;
        & :last-child{
            border-radius:  0 0 5px 5px;
        }
        .Attribute{
            width: 300px;
            word-wrap: break-word;
        }
        .Buttons{
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            width: 300px;
            align-items: center;
            text-align: center;
            min-height: 30px;
            height: fit-content;
            .EditButton{
                background-color: #ccc;
                border: 1px solid #ccc;
                border-radius: 5px;
                width: 50px;
                height: 30px;
                padding: 5px;
                margin: 5px;
                cursor: pointer;
                &:hover{
                    background-color: #aaa;
                    border: 1px solid #aaa;
                }
            }
            .DeleteButton{
                background-color: crimson;
                border: 1px solid #ccc;
                border-radius: 5px;
                padding: 5px;
                color: #ccc;
                width: 30px;
                height: 30px;
                margin: 5px;
                cursor: pointer;
                &:hover{
                    background-color: darkred;
                    border: 1px solid #aaa;
                }
            }
        }

    }
}