.Container {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  box-shadow: 0 0 10px #ccc;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  .Attribute {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    text-align: left;
    width: 100%;
    margin-bottom: 10px;
    h3 {
      width: 200px;
      height: fit-content;
      word-wrap: break-word;
      font-weight: bold;
    }
    .AttributeValue {
      width: 100%;
      max-width: 500px;
      word-wrap: break-word;
    }
  }
}
