.Link {
    text-decoration: none;
}
.ShortContainer {
    width: 136px;
    height: 230px;
    background: #fff;
    text-decoration: none;
    padding: 9px 5px;
    z-index: 1;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .Item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        align-items: center;
        .Image {
            width: 100%;
            height: 126px;
            margin-bottom: 5px;
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .ItemInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .Name {
            color: var(--text-main, #1e1e1e);
            font-family: Nuckle;
            font-size: 16px;
            font-weight: 539;
            max-width: 100%;
            text-align: center;
            word-wrap: break-word;
            word-break: break-all;
            min-height: 40px;
            margin-bottom: 10px;
        }
        .Subtitle {
            color: #c6c6c6;
            font-family: Nuckle;
            font-size: 12px;
            font-weight: 539;
        }
    }
}

.Container {
    width: 320px;
    height: 320px;
    @media screen and (max-width: 768px) {
        width: 150px;
        height: 250px;
        padding: 7px;
    }
    padding: 20px 35px;
    background-color: #fff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    .Item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 1;
        align-items: center;
        .Image {
            width: 200px;
            height: 200px;
            margin-bottom: 5px;
            @media screen and (max-width: 768px) {
                width: 130px;
                height: 130px;
            }
            > img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
    }
    .ItemInfo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .Name {
            color: var(--text-main, #1e1e1e);
            font-family: Nuckle;
            font-size: 16px;
            font-weight: 639;
            max-width: 100%;
            text-align: center;
            word-wrap: break-word;
            word-break: break-all;
            min-height: 40px;
            margin-bottom: 10px;
        }
        .Subtitle {
            color: #c6c6c6;
            font-family: Nuckle;
            font-size: 16px;
            font-weight: 639;
        }
    }
}
