.FormContainer{
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    >h1{
        margin-bottom: 20px;
        font-size: 20px;
        font-weight: bold;
        color: black;
    }
    > form{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        background-color: #ccc;
        padding: 30px 40px;
        border-radius: 5px;
        > button{
            background-color: #007bff;
            color: #fff;
            border: none;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 16px;
            cursor: pointer;
            transition: background-color 0.3s ease-in-out;
            &:hover{
                background-color: #0062cc;
            }
        }

    }
}