.Container{
    width: 100%;
    display: flex;
    justify-content: center;
}
.SingleItemPage{
    display: flex;
    align-self: center;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
    width: 70%;
    margin-top: 50px;
    height: 100%;
    background-color: #f5f5f5;
    .ItemInfo{
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    > .EditAndDelete{
        display: flex;
        flex-direction: column;
        margin: 20px 0px;
        width: 100%;
        height: fit-content;
        .DeleteButton{
            background-color: crimson;
            border: 1px solid #ccc;
            border-radius: 5px;
            width: 100px;
            height: 30px;
            margin: 0px auto;
            &:hover{
                background-color: darkred;
                border: 1px solid #fdd;
            }
        }
    }
}