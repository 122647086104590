.Homepage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #f5f5f5;
    height: 100vh;
    .Divider{
        width: 1000px;
        height: 1px;
        background-color: #ccc;
        margin: 20px 0 20px 0;
    }
    h1 {
        font-size: 30px;
        font-weight: 800;
        margin-bottom: 20px;
    }
    .Paragraph {
        font-size: 16px;
        font-weight: 600;
        max-width: 700px;
        margin-bottom: 20px;
    }
    .ContactInfo {
        background-color: #ccc;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        align-self: center;
        border-radius: 10px;
        width: 500px;
        height: fit-content;
        .Contact {
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 10px;
        }
    }
}
