.Container {
  margin-top: 70px;
  padding: 0px 11vw;
  @media screen and (max-width: 950px) {
    margin-top: 0px;
    padding: 0px 2vw;
  }
  .Cover {
    width: 100%;
    height: 1000px;
    display: flex;
    @media (max-width: 768px) {
      height: 500px;
    }
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 1000px;
      object-fit: cover;
      position: absolute;
      top: 70px;
      left: 0;
      @media (max-width: 768px) {
        top: 180px;
        height: 500px;
      }
    }
    .Backdrop {
      width: 100%;
      height: 1000px;
      position: absolute;
      top: 70px;
      left: 0;
      z-index: 1;
      opacity: 0.5;
      @media (max-width: 768px) {
        height: 500px;
        top: 180px;
      }
      background-color: rgba(0, 0, 0, 0.5);
    }
    .CoverText {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      font-weight: 600;
      text-align: center;
      font-size: 3rem;
      height: fit-content;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
      > p {
        max-width: 80%;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  .Empty {
    height: 70vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    > p {
      font-size: 22px;
      font-family: Nuckle;
      font-weight: 600;
      text-align: center;
      color: var(--neutral-200, #9d9ea2);
      @media screen and (max-width: 768px) {
        font-size: 4.5vw;
      }
      > a {
        &:hover {
          text-decoration: underline;
        }
        text-decoration: none;
      }
    }
  }
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ecf4ff;
  .Services {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    row-gap: 40px;
    column-gap: 30px;
    h2 {
      width: 100%;
      display: flex;
      font-size: 36px;
      font-weight: 600;
      margin-bottom: 20px;
      @media screen and (max-width: 950px) {
        font-size: 24px;
      }
    }
    .ServicesColumn {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > div {
        margin-bottom: 20px;
      }
    }
  }
}
