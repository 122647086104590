.Container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 70px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  .Cover {
    width: 100%;
    height: 1000px;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
      height: 500px;
    }
    img {
      width: 100%;
      height: 1000px;
      object-fit: cover;
      position: absolute;
      top: 70px;
      left: 0;
      @media (max-width: 768px) {
        top: 180px;
        height: 500px;
      }
    }
    .Backdrop {
      width: 100%;
      height: 1000px;
      position: absolute;
      top: 70px;
      left: 0;
      z-index: 1;
      opacity: 0.75;
      @media (max-width: 768px) {
        height: 500px;
        top: 180px;
      }
      background-color: rgba(0, 0, 0, 0.5);
    }
    .CoverText {
      z-index: 2;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: white;
      font-weight: 600;
      text-align: center;
      font-size: 3rem;
      height: fit-content;
      font-weight: 700;
      @media (max-width: 768px) {
        font-size: 2rem;
      }
      > p {
        width: 80%;
        @media (max-width: 768px) {
          font-size: 1rem;
        }
      }
    }
  }
  .CategoriesPage {
    background-color: #ecf4ff;
    padding: 50px 11vw;
    @media (max-width: 768px) {
      padding: 50px 5vw;
    }
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    .Categories {
      width: 100%;
      flex-direction: row;
      display: flex;
      flex-wrap: wrap;
      column-gap: 15px;
      row-gap: 25px;
      justify-content: center;
      align-items: flex-start;
    }
  }
}

.Empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  > h2 {
    font-size: 40px;
    font-family: Nuckle;
    font-weight: 600;
    text-align: center;
    color: var(--neutral-200, #9d9ea2);
    @media screen and (max-width: 768px) {
      font-size: 8vw;
    }
  }
  > p {
    font-size: 22px;
    font-family: Nuckle;
    font-weight: 600;
    text-align: center;
    color: var(--neutral-200, #9d9ea2);
    @media screen and (max-width: 768px) {
      font-size: 4.5vw;
    }
    > a {
      &:hover {
        text-decoration: underline;
      }
      text-decoration: none;
    }
  }
}
