.Form {
  align-self: center;
  margin: 30px 0px;
}
.Status{
  color: black;
  font-size: 16px;
  min-height: 20px;
  margin-top: 10px;
}

.DateSpan{
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
}

.ProductForm > form {
  display: flex;
  width: 700px;
  flex-direction: column;
  background-color: #d0d0d0;
  padding: 30px 40px;
  height: 1000px;
  justify-content: space-around;
  align-items: center;
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #0062cc;
    }
  }
}

.Form > form {
  display: flex;
  max-width: 800px;
  flex-direction: column;
  background-color: #d0d0d0;
  padding: 30px 40px;
  height: fit-content;
  button {
    background-color: #007bff;
    color: #fff;
    border: none;
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;

    &:hover {
      background-color: #0062cc;
    }
  }
}
