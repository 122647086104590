.Container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #ecf4ff;
  margin-top: 70px;
  .UserPage {
    margin-top: 250px;
    width: 100%;
    background-color: #fff;
    .User {
      @media screen and (max-width: 950px) {
        position: relative;
        top: -200px;
        border: hidden;
      }
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      border: 1px solid #0d63f3;
      padding: 20px 4vw;
      @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .NotFound {
        width: 100%;
        display: flex;
        text-align: center;
        flex-direction: column;
        align-items: center;
        font-size: 2vw;
        font-family: Nuckle;
        font-weight: 500;
        color: var(--neutral-200, #9d9ea2);
        @media (max-width: 768px) {
          font-size: 4.5vw;
        }
      }
      .Column {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        @media screen and (max-width: 950px) {
          width: 100%;
          align-items: center;
          justify-content: center;
        }
        > button {
          position: relative;
          top: -150px;
          border: none;
          outline: none;
          border-radius: 20px;
          width: 100%;
          height: 60px;
          background: var(
            --button-gradient,
            linear-gradient(270deg, #4d5ef6 0%, #f64d4d 100%)
          );
          color: #fff;
          font-family: Nuckle;
          font-size: 1.25vw;
          font-style: normal;
          font-weight: 591;
          line-height: normal;
          @media screen and (max-width: 950px) {
            position: relative;
            top: 0px;
            width: 70%;
            height: 40px;
            margin-bottom: 35px;
            font-size: 16px;
          }
          &:hover {
            cursor: pointer;
            background: var(
              --button-gradient-hover,
              linear-gradient(230deg, #1d5ef6 20%, #a34d4d 100%)
            );
          }
          &:active {
            background: var(
              --button-gradient-active,
              linear-gradient(230deg, #9d5ef6 20%, #c94d4d 100%)
            );
          }
        }
        > .Info {
          height: 35vw;
          display: flex;
          min-height: 400px;
          align-items: center;
          width: 30vw;
          position: relative;
          top: -200px;
          justify-content: space-around;
          background-color: #fff;
          border-radius: 20px;
          box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
          flex-direction: column;
          @media screen and (max-width: 950px) {
            width: 70%;
            height: 100%;
            top: 0px;
            margin-bottom: 30px;
          }
          .Image {
            width: 13vw;
            height: 13vw;
            min-width: 150px;
            min-height: 150px;
            border-radius: 10px;
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
          .Name {
            color: var(--text-main, #1e1e1e);
            font-family: Nuckle;
            font-size: 2vw;
            font-style: normal;
            font-weight: 591;
            line-height: normal;
            @media screen and (max-width: 950px) {
              font-size: 20px;
            }
          }
          .Email {
            color: var(--text-main, #1e1e1e);
            font-family: Nuckle;
            font-size: 1.25vw;
            font-style: normal;
            font-weight: 591;
            line-height: normal;
            @media screen and (max-width: 950px) {
              font-size: 16px;
            }
          }
          .AddCard {
            display: none;
          }
          .Stats {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            width: 100%;
            .Stat {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-around;
              width: 100%;
              @media screen and (max-width: 950px) {
                padding: 0 20px;
              }
              > span {
                width: 10vw;
                display: flex;
                @media screen and (max-width: 950px) {
                  width: 100%;
                }
                &:last-child {
                  color: var(--text-main, #1e1e1e);
                  font-family: Nuckle;
                  font-size: 1.25vw;
                  font-style: normal;
                  font-weight: 691;
                  line-height: normal;
                  margin-bottom: 10px;
                  display: flex;
                  text-align: right;
                  justify-content: flex-end;
                  align-items: center;
                  @media screen and (max-width: 950px) {
                    font-size: 16px;
                  }
                }
                &:first-child {
                  color: var(--text-main, #1e1e1e);
                  font-family: Nuckle;
                  font-size: 1.25vw;
                  font-style: normal;
                  font-weight: 391;
                  line-height: normal;
                  @media screen and (max-width: 950px) {
                    font-size: 16px;
                  }
                }
              }
            }
          }
        }
      }
      .Selection {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @media screen and (max-width: 950px) {
          width: 100%;
          align-items: center;
        }
        .Options {
          display: flex;
          flex-direction: row;
          justify-content: space-around;
          align-items: flex-start;
          width: 100%;
          @media screen and (max-width: 950px) {
            justify-content: center;
            align-items: center;
          }
          .Option {
            width: 100%;
            height: 70px;
            margin-right: 20px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            border-radius: 10px;
            background-color: #0d63f3;
            color: #fff;
            font-family: Nuckle;
            text-decoration: none;
            font-size: 1.05vw;
            font-style: normal;
            font-weight: 591;
            line-height: normal;
            :last-child {
              margin-right: 0px;
            }
            cursor: pointer;
            @media screen and (max-width: 950px) {
              display: flex;
              width: 100%;
              height: 40px;
              font-size: 2.5vw;
              margin-right: 5px;
              word-wrap: break-word;
              &:last-child {
                margin-right: 0px;
              }
            }
            &:hover {
              background-color: #fff;
              color: #0d63f3;
              border: 1px solid #0d63f3;
            }
            &:active {
              background-color: #0d63f3;
              color: #fff;
              border: 1px solid #0d63f3;
            }
          }
          margin-bottom: 50px;
          .Active {
            background-color: #fff;
            color: #0d63f3;
            border: 1px solid #0d63f3;
          }
        }
        .Content {
          display: flex;
          height: 100%;
          width: 100%;
          min-width: 50vw;
          flex-direction: column;
          .SelectRow {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: flex-end;
            > div {
              &:first-child {
                margin-right: 20px;
              }
            }
          }
          .Orders {
            width: 50vw;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
          .Form {
            width: 100%;
            height: 100%;
            border-radius: 10px;
            border: 1px #ecf4ff solid;
            display: flex;
            align-items: center;
            flex-direction: column;
            text-align: center;
          }
        }
      }
    }
  }
}
