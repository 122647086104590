.RegisterPage {
    display: flex;
    width: 100%;
    height: 900px;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
    @media screen and (max-width: 950px) {
        margin-top: 0px;
    }
    .Backdrop {
        width: 100%;
        height: 900px;
        background: rgba(0, 0, 0, 0.5);
        position: absolute;
        z-index: 1;
        overflow-x: clip;
    }
    .BackgroundImage {
        width: 100%;
        height: 900px;
        position: absolute;
        z-index: 0;
        overflow-x: clip;
        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .RegisterContainer {
        height: fit-content;
        display: flex;
        width: 100%;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        z-index: 2;
    }
}
