.Input {
    width: 100%;
    height: 30px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    margin: 10px 0px;
    color: #333;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    &:focus {
        border-color: #333;
    }
    &.error {
        border-color: red;
    }
    &.success {
        border-color: green;
    }
}

.Element {
    width: 80%;
    min-height: 40px;
    max-width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    > select {
        width: 100%;
        height: 30px;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 0 10px;
        font-size: 16px;
        color: #333;
        margin: 10px 0px;
        outline: none;
        transition: border-color 0.3s ease-in-out;
        &:focus {
            border-color: #333;
        }
        &.error {
            border-color: red;
        }
        &.success {
            border-color: green;
        }
    }
}


.Element > label {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
    color: #333;
}

.TextArea {
    max-width: 100%;
    height: 100%;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0 10px;
    font-size: 16px;
    color: #333;
    margin: 10px 0px;
    outline: none;
    transition: border-color 0.3s ease-in-out;
    rows: 5;
    columns: 5;
    &:focus {
        border-color: #333;
    }
    &.error {
        border-color: red;
    }
    &.success {
        border-color: green;
    }
}

.ChekboxContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 30px;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
    .Checkbox {
        height: 100%;
        width: 30px;
        background-color: #ccc;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #333;
        border: 1px solid #ccc;
        cursor: pointer;
        &.checked {
            background-color: #333;
            color: #fff;
        }

        &:hover {
            background-color: #333;
            color: #fff;
        }
    }
}

.SchemaContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: fit-content;
    > .SchemaAttribute {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: flex-start;
        Input {
            align-self: center;
        }
        .RemoveButton {
            height: 30px;
            width: 30px;
            background-color: crimson;
            border-radius: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #ccc;
            border: 1px solid #ccc;
            margin-right: 5px;
            cursor: pointer;
            &:hover {
                background-color: darkred;
                color: #fff;
            }
        }
    }
    .AddButton {
        width: 100%;
        height: 30px;
        background-color: #231323;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #ccc;
        border: 1px solid #ccc;
        cursor: pointer;
        &:hover {
            background-color: darkblue;
            color: #fff;
        }
    }
}
