.Container{
    width: 100%;
    height: 100%;
    min-height:70vh;
    display: flex;
    background-color: #ecf4ff;
    flex-direction: column;
    padding: 0vw 11vw;
    align-items: center;
    .Text{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        width: 100%;
        font-family: Nuckle;
        .Title{
            font-size: 50px;
            font-weight: 700;
            color: #0d63f3;
            margin-bottom: 20px;
        }
        .Subtitle{
            font-size: 20px;
            font-weight: 500;
            color: #333232;
            margin-bottom: 20px;
        }
        >a{
            text-decoration: none;
            color: #0d63f3;
            font-size: 20px;
            font-weight: 500;
            &:hover{
                text-decoration: underline;
            }
            
        }
    }
    justify-content: center;
}