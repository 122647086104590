.Footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  width: 100%;
  background: var(
    --button-gradient-20,
    linear-gradient(
      270deg,
      rgba(77, 94, 246, 0.2) 0%,
      rgba(246, 77, 77, 0.2) 100%
    )
  );
  min-height: 450px;
  padding: 3.75vw 12vw;
  @media screen and (max-width: 950px) {
    padding: 40px 1.25vw;
  }
  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 950px) {
      align-items: flex-start;
      padding: 5vw;
    }
    .FooterContent {
      justify-content: center;
      display: flex;
      align-items: flex-start;
      flex-direction: row;
      margin-bottom: 40px;
      @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: flex-start;
      }
      .FooterSection {
        > h3 {
          font-weight: 600;
          margin-bottom: 20px;
        }
        margin-right: 75px;
        &:last-child {
          margin-right: 0;
        }
        font-family: Nuckle;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
        justify-content: flex-start;
        align-items: flex-start;
        a {
          text-transform: none;
        }
        .Link {
          text-decoration: none;
        }
        li {
          color: #1e1e1e;
          margin-bottom: 20px;
          text-decoration: none;
          &:hover {
            color: #1f1d1a;
            text-decoration: underline;
            cursor: pointer;
          }
          &:active {
            color: #1f1f1f;
            text-decoration: none;
            cursor: pointer;
          }
        }
        @media screen and (max-width: 950px) {
          margin-bottom: 20px;
          margin-right: 0;
        }
      }
      .InfoSection {
        margin-left: 0;
        &:last-child {
          margin-right: 0;
        }
        @media screen and (max-width: 950px) {
          margin-left: 0;
        }
        .Link {
          text-decoration: none;
          color: #000;
          &:hover {
            color: #1f1d1a;
            text-decoration: underline;
            cursor: pointer;
          }
        }
        li {
          margin-bottom: 20px;
          color: #1e1e1e;
          text-decoration: none;
          &:last-child {
            margin-bottom: 0;
          }
        }
        font-family: Nuckle;
        font-size: 16px;
        display: flex;
        flex-direction: column;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
        justify-content: flex-start;
        align-items: flex-start;
        .Social {
          display: flex;
          flex-direction: row;
          margin-top: 27px;
          @media screen and (max-width: 950px) {
            margin-top: 60px;
            align-self: center;
          }
          img {
            margin-right: 27px;
            width: 42px;
            height: 42px;
            &:hover {
              cursor: pointer;
              opacity: 0.8;
            }
          }
        }
      }
    }
    .Divider {
      width: 75vw;
      align-self: center;
      background: linear-gradient(270deg, #4d5ef6 0%, #f64d4d 100%);
      height: 1px;
    }
    .Author {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      > .a {
        text-decoration: none;
        color: #1e1e1e;
        font-family: Nuckle;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.32px;
        &:hover {
          color: #1f1d1a;
          text-decoration: underline;
          cursor: pointer;
        }
        &:active {
          color: #1f1f1f;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }
}
