.AdminNavigation {
    padding: 0 0 0 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-color: #E5E4E2;
    z-index: 100;
    Link {
        text-decoration: none;
        color: black;
    }
    ul {
        justify-content: space-between;
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        align-items: center;
        margin: 0;
        li {
            font-size: 14px;
            font-weight: 600;
            list-style: none;
            transition: background-color, color 0.1s ease-in-out;
            padding: 0 10px;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
            &:hover {
                background-color: #ccc;
                font-weight: 900;
            }
            > .Link{
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                text-align: center;
                align-items: center;
            }
        }
    }
}
