.Container {
  width: 100%;
  background-color: #fff;
  margin-bottom: 40px;
  border-radius: 20px;
  .PreviousClient {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    .Image {
      width: 100%;
      height: 20vw;
      margin-bottom: 40px;
      border-radius: 20px;
      @media (max-width: 768px) {
        height: fit-content;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    .OtherInfo {
      flex-direction: column;
      display: flex;
      padding: 30px;
      align-items: center;
      justify-content: center;
      width: 70%;
      @media (max-width: 768px) {
        width: 100%;
        padding: 20px;
      }
      .Name {
        color: var(--light-base-black, #2c2c2c);
        font-family: Nuckle;
        font-size: 2.5vw;
        font-style: normal;
        font-weight: 700;
        margin-bottom: 20px;
        line-height: normal;
        text-align: center;
        word-break: break-word;
        @media (max-width: 768px) {
          font-size: 24px;
        }
      }
      .Rating {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;
        .Star {
          margin-right: 5px;
          font-size: 2.5vw;
          color: var(--background-main-color, #0d63f3);
          @media (max-width: 768px) {
            font-size: 24px;
          }
        }
      }
      .Site {
        color: var(--background-main-color, #0d63f3);
        font-family: Nuckle;
        font-size: 2vw;
        font-style: normal;
        font-weight: 500;
        @media (max-width: 768px) {
          font-size: 24px;
        }
        &:hover {
          color: var(--background-main-color, #0d93f1);
          text-decoration: underline;
        }
        text-decoration: none;
        line-height: normal;
      }
      .Description {
        color: var(--light-base-black, #2c2c2c);
        font-family: Nuckle;
        font-size: 1.5vw;
        min-height: 7vw;
        font-style: normal;
        text-align: center;
        font-weight: 600;
        border-radius: 10px;
        padding: 15px;
        line-height: normal;
        width: 100%;
        margin: 0 auto;
        margin-bottom: 20px;
        background-color: #f3f3f3;
        @media (max-width: 768px) {
          font-size: 16px;
          min-height: 5vw;
        }
      }
    }
  }
}
