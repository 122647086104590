.Container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px 11vw;
    background-color: #ecf4ff;
    border-radius: 10px;
    margin-top: 70px;
    @media (max-width: 768px) {
        padding: 20px 5vw;
    }
    .ClientsContainer{
        width: 100%;
    }
}
.Cover {
    width: 100%;
    height: 1000px;
    display: flex;
    @media (max-width: 768px) {
        height: 500px;
    }
    justify-content: center;
    align-items: center;
    img {
        size: cover;
        width: 100%;
        height: 1000px;
        object-fit: cover;
        position: absolute;
        top: 70px;
        left: 0;
        @media (max-width: 768px) {
            top: 180px;
            height: 500px;
        }
    }
    .Backdrop {
        width: 100%;
        height: 1000px;
        position: absolute;
        top: 70px;
        left: 0;
        z-index: 1;
        opacity: 0.5;
        @media (max-width: 768px) {
            height: 500px;
            top: 180px;
        }
        background-color: rgba(0, 0, 0, 0.5);
    }
    .CoverText {
        z-index: 2;
        position: relative;
        top: -50px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        color: white;
        font-weight: 600;
        text-align: center;
        font-size: 3rem;
        height: fit-content;
        font-weight: 700;
        @media (max-width: 768px) {
            font-size: 2rem;
        }
    }
}
.Empty{
    height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%;
    text-align: center;
    >h2{
        font-size: 40px;
        font-family: Nuckle;
        font-weight: 600;
        text-align: center;
        color: var(--neutral-200, #9d9ea2);
        @media screen and (max-width: 768px) {
            font-size: 8vw;
        }
    }
    >p{
        font-size: 22px;
        font-family: Nuckle;
        font-weight: 600;
        text-align: center;
        color: var(--neutral-200, #9d9ea2);
        @media screen and (max-width: 768px) {
            font-size: 4.5vw;
        }
        >a{
            &:hover{
                text-decoration: underline;
            }
            text-decoration: none;
        }
    }
}