.Page{
    background-color: #f5f5f5;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    padding: 30px 10px;
    >h1{
        margin-bottom: 20px;
        font-size: 30px;
        font-weight: bold;
        color: black;
    }
    >p{
        margin-bottom: 20px;
        font-size: 22px;
        color: black;
    }
    .PageContainer{
        padding: 30px 100px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    .PageActions{
        display: flex;
        flex-direction: row;
        width: 50%;
        justify-content: space-between;
        align-items: flex-start;
    }
    
}