.Container {
    width: 490px;
    height: fit-content;
    background: #fff;
    border-radius: 20px;
    z-index: 2;
    &:hover {
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
    @media screen and (max-width: 768px) {
        width: 290px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center
    }
    .Register {
        display: flex;
        width: 100%;
        padding: 40px 50px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 768px) {
            padding: 40px 20px;
        }
        .RegisterFormHeader {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            > img {
                width: 60px;
                height: 60px;
                @media screen and (max-width: 768px) {
                    width: 40px;
                    height: 40px;
                }
            }
            > h1 {
                color: var(--black-h-1, #2c2c2c);
                font-family: Nuckle;
                font-size: 22px;
                font-style: normal;
            }
            .Link {
                text-decoration: none;
                color: #2952e1;
                font-size: 14px;
                font-family: Nuckle;
                font-weight: 600;
                @media screen and (max-width: 768px) {
                    font-size: 10px;
                }
                &:hover {
                    cursor: pointer;
                    text-decoration: underline;
                }
                &:active {
                    color: #2352e1;
                    text-decoration: none;
                }
            }
            margin-bottom: 40px;
        }
        .RegisterFormBody {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;
            > form {
                display: flex;
                flex-direction: column;
                width: 100%;
                align-items: center;
                justify-content: center;
                > div {
                    margin-bottom: 20px;
                    &:last-child {
                        margin-bottom: 0px;
                    }
                }
                .Error {
                    color: var(--red-base, #ff0000);
                    font-family: Nuckle;
                    font-size: 12px;
                    font-weight: 600;
                    text-align: center;
                    margin-bottom: 10px;
                    min-height: 40px;
                }
                .Button {
                    border-radius: 10px;
                    background: var(--light-blue, #3f86ef);
                    color: var(--white, #fff);
                    font-family: Nuckle;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                    letter-spacing: 0.09px;
                    padding: 12px 80px;
                    text-align: center;
                    text-decoration: none;
                    border: none;
                    outline: none;
                    text-transform: none;
                    &:hover {
                        cursor: pointer;
                        background: var(--light-blue-hover, #3e26ef);
                    }
                    &:active {
                        background: linear-gradient(270deg, #4d5ef6 0%, #f64d4d 100%);
                    }
                }
            }
        }
    }
}
