.Container {
  margin-top: 70px;
  padding: 20px 11vw;
  background-color: #ecf4ff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  @media (max-width: 768px) {
    padding: 20px 2vw;
    margin-top: 0;
  }
  .NotFound {
    width: 100%;
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    font-size: 2vw;
    font-family: Nuckle;
    font-weight: 500;
    color: var(--neutral-200, #9d9ea2);
    @media (max-width: 768px) {
      font-size: 4.5vw;
    }
  }
  .SearchPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    .Title {
      font-size: 3.5rem;
      font-weight: 800;
      margin-bottom: 20px;
      font-family: Nuckle;
      text-align: center;
      @media (max-width: 768px) {
        font-size: 2.25rem;
      }
    }
    .Section {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      @media (max-width: 768px) {
        align-items: center;
        justify-content: center;
      }
      width: 100%;
      .Subtitle {
        font-size: 2.5rem;
        font-weight: 700;
        margin-bottom: 20px;
        font-family: Nuckle;
        @media (max-width: 768px) {
          font-size: 1.75rem;
        }
      }
      .SectionList {
        flex-wrap: wrap;
        display: flex;
        column-gap: 15px;
        row-gap: 25px;
        justify-content: flex-start;
        @media (max-width: 768px) {
          justify-content: center;
          align-items: flex-start;
        }
        width: 100%;
      }
      .ProductList {
        flex-wrap: wrap;
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        row-gap: 20px;
        width: 100%;
        @media screen and (max-width: 768px) {
          flex-direction: row;
          align-items: flex-start;
          justify-content: center;
        }
      }
    }
  }
}
